import React from "react";
import { Box, Typography } from "@mui/material";

const DesktopCompatible = () => {
  return (
    <Box
      sx={{
        display: { xs: "block", md: "none" },
        mt: 4,
        padding: 2,
        textAlign: "center",
        backgroundColor: "#f5f5f5",
        borderRadius: 1,
        boxShadow: "3px 2px 3px rgba(0, 0, 0, 0.15)",
        color: "#16359D"
      }}
    >
      <Typography variant="caption">
        <strong>
          Note: This site is optimized for desktop browsers. For the best
          experience, please use a desktop or laptop.
        </strong>
      </Typography>
    </Box>
  );
};

export default DesktopCompatible;
