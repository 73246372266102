import React from "react";
import {Routes, Route} from "react-router-dom";
import loadable from '@loadable/component';
import FullPageLoader from "../components/Shared/fullPageLoader";
import { SERVICE_IDENTITY } from "../js/constants";
import DefaultLayout from "../components/DefaultLayout";
import EmptyLayout from "../components/EmptyLayout";
import Loader from '../components/Shared/loader';
import Buy from '../components/Buy';
import Sell from '../components/Sell';
import Trade from '../components/Trade';
import CardPayment from "../components/Buy/cardPayment";
import ConfirmTransaction from '../components/Buy/confirmTransaction';
import SellConfirmTransaction from '../components/Sell/confirmTransaction';
import ManualSend from '../components/Shared/manualSend';

const Login = loadable(() => import('../components/UserLogin'), {
    fallback: <FullPageLoader />,
});

const PersonalDetails = loadable(() => import('../components/PersonalDetails'), {
    fallback: <FullPageLoader />,
});

const Documents = loadable(() => import('../components/PersonalDetails/documents'), {
    fallback: <FullPageLoader />,
});

const SignIn = loadable(() => import('../components/SignIn'), {
    fallback: <FullPageLoader />,
});

const SignUp = loadable(() => import('../components/SignUp'), {
    fallback: <FullPageLoader />,
});

const Kyc = loadable(() => import('../components/Kyc'), {
    fallback: <FullPageLoader />,
});

const Contact = loadable(() => import('../components/Contact'), {
    fallback: <FullPageLoader />,
});

const Currency = loadable(() => import('../components/Currency'), {
    fallback: <FullPageLoader />,
});

const Banks = loadable(() => import('../components/Banks'), {
    fallback: <FullPageLoader />,
});

const PassThruKyc = loadable(() => import('../components/PassThruKyc'), {
    fallback: <FullPageLoader />,
});

const OrderDetail = loadable(() => import('../components/Order/detail'), {
    fallback: <FullPageLoader />,
});

const IdentityWallet = loadable(() => import('../components/IdentityWallet/Home'), {
    fallback: <FullPageLoader />,
});

const IdentityWalletDashboard = loadable(() => import('../components/IdentityWallet/Dashboard'), {
    fallback: <FullPageLoader />,
});

const History = loadable(() => import('../components/History'), {
    fallback: <FullPageLoader />,
});

const Balance = loadable(() => import('../components/Wallet'), {
    fallback: <FullPageLoader />,
});

const Connect = loadable(() => import('../components/Wallet/connect'), {
    fallback: <FullPageLoader />,
});

const TokenInfo = loadable(() => import('../components/Wallet/tokenInfo'), {
    fallback: <FullPageLoader />,
});

const Send = loadable(() => import('../components/Wallet/send'), {
    fallback: <FullPageLoader />,
});

const Receive = loadable(() => import('../components/Wallet/receive'), {
    fallback: <FullPageLoader />,
});

const MyLink = loadable(() => import('../components/Wallet/myLink'), {
    fallback: <FullPageLoader />,
});

const ReferralRewards = loadable(() => import('../components/ReferralRewards'), {
    fallback: <FullPageLoader />,
});

const TwitterSignIn = loadable(() => import("../components/Shared/Twitter/signin"), {
    fallback: <FullPageLoader />,
});

const Withdraw = loadable(() => import('../components/Withdraw'), {
    fallback: <FullPageLoader />,
});

const BankSelect = loadable(() => import('../components/Withdraw/bankSelect'), {
    fallback: <FullPageLoader />,
});

const WithdrawConfirmTransaction = loadable(() => import('../components/Withdraw/confirmTransaction'), {
    fallback: <FullPageLoader />,
});

const ServiceUnavailable = loadable(() => import("../components/Shared/serviceUnavailable"), {
    fallback: <FullPageLoader />,
});

const CustomRoutes = ({renderApp, features, service, appData, partnerData}) => {

    if(renderApp) {
        return (
            <Routes>
                {service === SERVICE_IDENTITY ? 
                    <Route path="/" exact element={<EmptyLayout><IdentityWallet /></EmptyLayout>} /> :
                    <>
                        {features.buy && partnerData.buy_feature_active ?
                            <>
                                <Route path="/" exact element={<DefaultLayout showBackBtn={false}><Buy /></DefaultLayout>} />
                                <Route path="/confirm/transaction" exact element={<DefaultLayout showRefreshNotice={true} protectedRoute={partnerData.enable_auth} showMenu={false} showBackBtn={false}><ConfirmTransaction /></DefaultLayout>} />
                            </> :
                            <>
                                <Route path="/" exact element={<ServiceUnavailable />} />
                                <Route path="/confirm/transaction" exact element={<ServiceUnavailable />} />
                            </>
                        }
                    </>
                }
                {features.trade && partnerData.exchange_service_active ?
                    <>
                        <Route path="/swap" exact element={<DefaultLayout showBackBtn={false}><Trade /></DefaultLayout>} />
                        <Route path="/swap/send" exact element={<DefaultLayout protectedRoute={partnerData.enable_auth} showBackBtn={false} showMenu={false}><ManualSend from="swap" /></DefaultLayout>} />
                    </> :
                    <>
                        <Route path="/swap" exact element={<ServiceUnavailable />} />
                        <Route path="/swap/send" exact element={<ServiceUnavailable />} />
                    </>
                }
                {features.sell && partnerData.sell_feature_active ?
                    <>
                        <Route path="/sell" exact element={<DefaultLayout showBackBtn={false}><Sell /></DefaultLayout>} />
                        <Route path="/sell/confirm/transaction" exact element={<DefaultLayout showRefreshNotice={true} protectedRoute={partnerData.enable_auth} showMenu={false} showBackBtn={false}><SellConfirmTransaction /></DefaultLayout>} />
                        <Route path="/sell/send" exact element={<DefaultLayout protectedRoute={partnerData.enable_auth} showBackBtn={false} showMenu={false}><ManualSend from="sell" /></DefaultLayout>} />
                    </> :
                    <>
                        <Route path="/sell" exact element={<ServiceUnavailable />} />
                        <Route path="/sell/confirm/transaction" exact element={<ServiceUnavailable />} />
                        <Route path="/sell/send" exact element={<ServiceUnavailable />} />
                    </>
                }
                {features.withdraw ? 
                    <>
                        <Route path="/withdraw" exact element={<DefaultLayout protectedRoute={true} showBackBtn={false}><Withdraw /></DefaultLayout>} />
                        <Route path="/account/select" exact element={<DefaultLayout protectedRoute={true} showBackBtn={false} showMenu={false}><BankSelect /></DefaultLayout>} />
                        <Route path="/withdraw/confirm/transaction" exact element={<DefaultLayout showRefreshNotice={true} protectedRoute={true} showMenu={false} showBackBtn={false}><WithdrawConfirmTransaction /></DefaultLayout>} />
                    </> :
                    <>
                        <Route path="/withdraw" exact element={<ServiceUnavailable />} />
                        <Route path="/account/select" exact element={<ServiceUnavailable />} />
                        <Route path="/withdraw/confirm/transaction" exact element={<ServiceUnavailable />} />
                    </>
                }
                <Route path="/login" exact element={<Login />} />
                <Route path="/connect-wallet" exact element={<EmptyLayout><Connect /></EmptyLayout>} />
                <Route path="/balance" exact element={<DefaultLayout protectedRoute={true} menuText={appData.menuText}><Balance /></DefaultLayout>} />
                <Route path="/token/info" exact element={<DefaultLayout protectedRoute={true} menuText={appData.menuText}><TokenInfo /></DefaultLayout>} />
                <Route path="/send" exact element={<DefaultLayout protectedRoute={true} menuText={"Send"}><Send /></DefaultLayout>} />
                <Route path="/receive" exact element={<DefaultLayout protectedRoute={true} menuText={"Receive"}><Receive /></DefaultLayout>} />
                <Route path="/my-link" exact element={<DefaultLayout protectedRoute={true} menuText={"My Link"}><MyLink /></DefaultLayout>} />
                <Route path="/referral-rewards" exact element={<DefaultLayout menuText={""}><ReferralRewards /></DefaultLayout>} />
                <Route path="/dashboard" exact element={<DefaultLayout showBackBtn={false} protectedRoute={true}><IdentityWalletDashboard /></DefaultLayout>} />
                <Route path="/history" exact element={<DefaultLayout menuText={"History"} protectedRoute={true} showBackBtn={true}><History /></DefaultLayout>} />
                <Route path="/signin" exact element={<DefaultLayout><SignIn /></DefaultLayout>} />
                <Route path="/signup" exact element={<DefaultLayout menuText={"Sign up"}><SignUp /></DefaultLayout>} />
                <Route path="/contact" exact element={<DefaultLayout><Contact /></DefaultLayout>} />
                <Route path="/currency" exact element={<DefaultLayout><Currency /></DefaultLayout>} />
                <Route path="/documents" exact element={<DefaultLayout protectedRoute={true}><Documents /></DefaultLayout>} />
                <Route path="/banks" exact element={<DefaultLayout protectedRoute={true}><Banks /></DefaultLayout>} />
                <Route path="/personal-details" exact element={<DefaultLayout protectedRoute={true}><PersonalDetails /></DefaultLayout>} />
                <Route path="/kyc" exact element={<DefaultLayout menuText={"KYC check"} protectedRoute={true} showMenu={false} showBackBtn={true}><Kyc /></DefaultLayout>} />
                <Route path="/card/payment" exact element={<DefaultLayout protectedRoute={true} showMenu={false} showBackBtn={false}><CardPayment /></DefaultLayout>} />
                <Route path="/kyc-pass" exact element={<DefaultLayout menuText={"Name, Birthdate, Address"} protectedRoute={true} showMenu={false} showBackBtn={true}><PassThruKyc /></DefaultLayout>} />
                <Route path="/twitter" exact element={<DefaultLayout showBackBtn={false} protectedRoute={true}><TwitterSignIn /></DefaultLayout>} />
                <Route path="/order/:orderId" exact element={<DefaultLayout menuText={"Your Order"} protectedRoute={true} showMenu={false} showBackBtn={true}><OrderDetail /></DefaultLayout>} />
            </Routes>
        );
    } else {
        return (
            <Routes>
                <Route path="*" element={<Loader />} />
            </Routes>
        );
    }

};

export default CustomRoutes;