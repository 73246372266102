import React, {useState, useEffect, useRef} from 'react';
import web3 from "web3";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    Typography,
    Button,
    InputLabel,
    Grid,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    ListItemIcon,
    FormControl,
    FormHelperText,
    FilledInput,
    useTheme,
    Dialog,
    DialogContent
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoIcon from '@mui/icons-material/Info';
import helpers, { cancelToken } from '../../adapters/helpers';
import {reset, setData} from './reduxSlice';
import {selectAppData} from "../../store/reduxSlice";
import {
    DEFAULT_CRYPT_TOKEN,
    ESTIMATE_TOOLTIP_TEXT,
    EVER_WALLET_NAME,
    META_MASK,
    REGION_EU,
    REGION_US,
    TOKEN_TO_TOKEN,
    VIAARPE_APP_KEY
} from "../../js/constants";
import {getCentralLedgetNetwork, getCurrencySymbol, getNetworkBackGround} from "../../util/generalUtil";
import GenericTokenImage from "../../images/generic-token.png";
import BootstrapTooltip from "../Shared/bootstrapToolTip";
import BuySellLink from "../Shared/buySellLink";
import {selectUserData} from '../../store/reduxUserSlice';
import {setConfirmDialog} from '../Shared/ConfirmDialog';
import DialogSuccess from "../../images/dialog-success.png";
import {isLoggedIn} from '../../util/userFunctions';
import ConnectWallet from '../Shared/connectWallet';
import {getEthBalance, getTokenBalance, sendProviderTransaction, validateTxHash} from '../../util/web3';
import TokenModal from '../Shared/TokenModal';
import {ARROW_ICON} from '../../js/images';
import {handleOrderError} from '../Shared/errorHandler';
import { formatNumberWithCommas, formatNumberWithoutRounding } from '../../util/conversions';
import { useSDK } from '@metamask/sdk-react';
import DesktopCompatible from '../Shared/desktopCompatible';

let i = 0, assetSource;
const Trade = ({snackBarMessage}) => {
    const isSignIn = isLoggedIn();
    const timeoutRef = useRef();
    const appData = useSelector(selectAppData);
    const theme = useTheme();

    const appKey = appData.sdkConfiguration.appKey;
    const referralCode = appData.referralCode;
    const partnerData = appData.partnerData;
    const enableAuth = partnerData.enable_auth;
    const providerConnectWallet = appData.connectWallet;
    const walletProvider = appData.connectWalletProvider;
    const isSignAndAuth = isSignIn && enableAuth;
    const userRegion = useSelector(selectUserData).region;
    const [estimateTokenValue, setEstimateTokenValue] = useState('');
    const [conversionRate, setConversionRate] = useState('');

    const [amount, setAmount] = useState('');
    const [amountErr, setAmountErr] = useState(false);
    const [allTokens, setAllTokens] = useState([]);
    const [tokenPairs, setTokenPairs] = useState({});
    const [estimateLoader, setEstimateLoader] = useState(false);
    const [fee, setFee] = useState(0);
    const [everestFee, setEverestFee] = useState(0);
    const [networkFee, setNetworkFee] = useState(0);
    const [quoteId, setQuoteId] = useState('');

    const [openOutputModal, setOpenOutputModal] = useState(false);
    const [outputTokenLoader, setOutputTokenLoader] = useState(false);
    const [outputTokenList, setOutputTokenList] = useState([]);
    const [outputToken, setOutputToken] = useState({ id: '' });

    const [openInputTokenModal, setOpenInputTokenModal] = useState(false);
    const [inputTokenLoader, setInputTokenLoader] = useState(true);
    const [inputTokenList, setInputTokenList] = useState([]);
    const [inputToken, setInputToken] = useState({ id: '' });
    const [tokenDefault, setTokenDefault] = useState({});


    const [balanceTokenList, setBalanceTokenList] = useState([]);
    const [showFeeCalculation, setShowFeeCalculation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputFiatAmount, setInputFiatAmount] = useState(0);
    const [outputFiatAmount, setOutputFiatAmount] = useState(0);

    const [everWallet, setEverWallet] = useState(true);
    const [manualSend, setManualSend] = useState(false);
    const [connectWallet, setConnectWallet] = useState('');
    const [selectWallet, setSelectWallet] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [loader, setLoader] = useState(true);
    const [providerLoader, setProviderLoader] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [associateWalletList, setAssociateWalletList] = useState([]);
    const [linkedWalletList, setLinkedWalletList] = useState([]);
    const [tokenSymbols, setTokenSymbols] = useState([]);
    const [minimumAmount, setMinimumAmount] = useState(50);
    const [maximumAmount, setMaximumAmount] = useState(10000);
    const [errorText, setErrorText] = useState("");

    const amountCal = amount.replaceAll(",", "") * 1;
    let exchangeRate = "0.00";
    if (amount && conversionRate) {
        exchangeRate = conversionRate;
    }
    const regexAmountCheck = /^(\d{0,3})(,\d{3})*(\.\d+)?$/; //eslint-disable-line
    const currency = "USD";
    const currencySymbol = getCurrencySymbol(currency);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { provider, connected } = useSDK();

    useEffect(() => {
        if(providerConnectWallet && allTokens.length > 0) {
            setWalletAddress(providerConnectWallet);
            setConnectWallet(providerConnectWallet);
        }
    }, [providerConnectWallet, allTokens]);

    useEffect(() => {
        if(partnerData.exchange_initial_amount) {
            const defaultAmount = formatNumberWithCommas(formatNumberWithoutRounding(partnerData.exchange_initial_amount, 8));
            setAmount(defaultAmount);
        }
    }, [partnerData.exchange_initial_amount]);

    useEffect(() => {
        if (!walletAddress) {
            return;
        }

        setInputToken({id: ""});
        resetEstimations();
        getUserTokens();

        let wallet = false, manualSend = true;
        for (let i = 0; i < linkedWalletList.length; i++) {
            if (linkedWalletList[i].address.toLowerCase() === walletAddress.toLowerCase()) {
                manualSend = false;
                if (linkedWalletList[i].name === EVER_WALLET_NAME) {
                    wallet = true;
                    break;
                }
            }
        }
        setEverWallet(wallet);
        setManualSend(manualSend);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress]);

    useEffect(() => {
        if (isSignAndAuth && connectWallet && associateWalletList.length > 0) {
            const delay = setTimeout(() => {
                associateWallet();
            }, 1000);

            return () => clearTimeout(delay)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectWallet, associateWalletList]);

    useEffect(() => {
        if(connectWallet) {
            setAmount('');
        }
    }, [connectWallet]);

    useEffect(() => {
        const { tokenArr, symbolsArr } = processTokens(
            allTokens,
            userRegion,
            everWallet,
            walletAddress
        );

        setOutputTokenList(tokenArr);
        setTokenSymbols(symbolsArr);
        if (!walletAddress) {
            setInputTokenLoader(false);
            setInputTokenList(tokenArr);
            setBalanceTokenList(tokenArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [everWallet, userRegion, allTokens]);

    useEffect(() => {
        if (!inputToken.id || !tokenPairs[inputToken.id]) {
            setOutputTokenList([]);
            setOutputToken({id: ''});
            return;
        }

        const filteredTokens = allTokens.filter((token) => 
            tokenPairs[inputToken.id].includes(token.id) && (everWallet || (!everWallet && !token.synthetic))
        );

        setOutputTokenList(filteredTokens);
        if(filteredTokens.length > 0) {
            const opTokens = tokenPairs[inputToken.id];
            let dOpToken;
            for (const [_, pairs] of Object.entries(tokenPairs[inputToken.id])) {
                if(outputToken.id === pairs * 1) {
                    dOpToken = pairs * 1;
                    break;
                }
                dOpToken = pairs * 1;
            }
            if(dOpToken && opTokens.indexOf(dOpToken) > -1) {
                let setopToken = false;
                for(let i = 0; i < filteredTokens.length; i++) {
                    if(filteredTokens[i].id === dOpToken) {
                        setopToken = true;
                        setOutputToken(filteredTokens[i]);
                        break;
                    }
                }
                if(!setopToken) {
                    setOutputToken(filteredTokens[0]);
                }
            }
        }
    }, [walletAddress, inputToken, tokenPairs, allTokens]);

    useEffect(() => {
        if (amount !== "") {
            if (!regexAmountCheck.test(amount)) {
                setEstimateLoader(false);
                setEstimateTokenValue('');
                setErrorText('Please provide the valid amount');
                setAmountErr(true);
                return false;
            } else {
                setAmountErr(false);
                setErrorText("");
            }
        }
        if (amountCal > 0 && outputToken.id && inputToken.id && outputTokenList.length > 0) {
            setEstimateTokenValue('');
            resetEstimations();
            const delay = setTimeout(() => {
                getEstimations();
            }, 500);

            return () => clearTimeout(delay)
        } else {
            resetEstimations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, inputToken, outputToken, outputTokenList]);

    useEffect( () => {
        if (isSignIn || !enableAuth) {
            getSwapTransactionsData();
        }

        setOutputTokenLoader(true);
        Promise.all([
            loadTokenPairs().then((resp) => resp),
            loadCryptoAssets().then((resp) => resp)
        ]).then(([tokenPairs , cryptoAssets]) => {
            setOutputTokenLoader(false);

            const tokenResp = tokenPairs.data;
            const cryptoAssetsResp = cryptoAssets.data;

            if (!tokenResp.success) {
                return;
            }
            if (!cryptoAssetsResp.success) {
                return;
            }

            const totalTokens = cryptoAssetsResp.data.length;
            const allTokenArr = [];
            for (let i = 0; i < totalTokens; i++) {
                const currentToken = cryptoAssetsResp.data[i];

                if (!currentToken.active || !currentToken.tradeable) {
                    continue;
                }

                allTokenArr.push({
                    id: currentToken.id,
                    name: currentToken.name,
                    symbol: currentToken.symbol,
                    contractAddress: currentToken.address,
                    decimal: currentToken.decimal,
                    token: currentToken.token,
                    crypto: currentToken.crypto,
                    synthetic: currentToken.synthetic,
                    image: currentToken.logo_uri,
                    network: currentToken.network.name,
                    networkImage: currentToken.network.logo_uri,
                    networkSymbol: currentToken.network.symbol,
                    exchange: currentToken.exchange.name,
                    regionUs: currentToken.region_us,
                    regionEu: currentToken.region_eu,
                });
            }
            setTokenDefault(tokenResp.data.default_asset_swaps)
            setTokenPairs(tokenResp.data.asset_swaps);
            setAllTokens(allTokenArr);
        })
    }, []);

    useEffect(() => {
        if (isSignAndAuth) {
            Promise.all([
                getUserWallets().then((resp) => resp),
                getAssociatedWallets().then((resp) => resp),
            ]).then(([walletResp, associatedWallets]) => {
                if (associatedWallets.data.success && associatedWallets.data.wallets) {
                    setAssociateWalletList(associatedWallets.data.wallets);
                }
                setLoader(false);
                if (walletResp.data.wallets) {
                    const wallets = walletResp.data.wallets;
                    setLinkedWalletList(wallets);
                    const walletsArr = [];
                    const addressArr = [];
                    for (let i = 0; i < wallets.length; i++) {
                        const walletObj = {name: wallets[i].name, address: wallets[i].address};
                        walletsArr.push(walletObj);
                        addressArr.push(wallets[i].address);
                    }
                    if (associatedWallets.data.success && associatedWallets.data.wallets) {
                        const wallets = associatedWallets.data.wallets;
                        for (let i = 0; i < wallets.length; i++) {
                            if (addressArr.indexOf(wallets[i].address) < 0) {
                                const walletObj = {name: wallets[i].name, address: wallets[i].address};
                                walletsArr.push(walletObj);
                            }
                        }
                        setWalletList(walletsArr);
                    } else {
                        setWalletList(walletsArr);
                    }
                } else {
                    window.alert(
                        'Something went wrong while getting wallets data.'
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWalletChange = (e) => {
        const address = e.target.value;
        setSelectWallet(address);
        setWalletAddress(address);
        setConnectWallet('');
    };

    const handleConnectWalletChange = (address) => {
        setConnectWallet(address);
        setWalletAddress(address);
        setSelectWallet('');
    };

    const loadCryptoAssets = () =>{
        return helpers.cryptoAssets()
            .then(response => {
                return response;
            })
            .catch((error) => {
                setOutputTokenLoader(false);
                snackBarMessage({message: error.toString()});
            });
    }

    const loadTokenPairs = () => {
        return helpers
            .cryptoAssetsPairs({appKey: appKey})
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({
                    message:
                        'Unable to load token pairs. Please refresh the page and try again.',
                });
            });
    };

    const processTokens = (tokens, userRegion, everWallet, walletAddress) => {
        let
            whitelistTokens = {},
            tokenArr = [],
            symbolsArr = [],
            inputTokenDefault =0,
            outputTokenDefault = 0;

        const handleTokenPush = (token, symbols, tokens) => {
            if (!whitelistTokens[token.id]) {
                return;
            }

            symbols.push(token.symbol);
            tokens.push(token);

            for (const [key, pairs] of Object.entries(tokenDefault)) {
                inputTokenDefault = key * 1;
                outputTokenDefault = pairs * 1;
            }


            // Handle Default Token Set
            if (!walletAddress && inputTokenDefault === token.id) {
                setInputToken(token);
            }

            if (!walletAddress && outputTokenDefault === token.id) {
                setOutputToken(token);
            }
        }

        for (const [key, pairs] of Object.entries(tokenPairs)) {
            whitelistTokens[key] = true;
            // pairs.forEach((element) => {
            //     whitelistTokens[element] = true;
            // })
        }

        tokens.forEach((currentToken) => {
            const isRegionUS =
                userRegion === REGION_US && currentToken.regionUs;
            const isRegionEU =
                userRegion === REGION_EU && currentToken.regionEu;
            const isEverWallet =
                everWallet || (!everWallet && !currentToken.synthetic);

            switch (isSignAndAuth){
                case true:
                    if ((isRegionUS || isRegionEU) && isEverWallet) {
                        handleTokenPush(currentToken, symbolsArr, tokenArr);
                    }
                    break;
                default:
                    if (isRegionEU && isEverWallet){
                        handleTokenPush(currentToken, symbolsArr, tokenArr);
                    }
                    break
            }
        });
        tokenArr.sort((a, b) => a.symbol.localeCompare(b.symbol));
        return { tokenArr, symbolsArr };
    };

    const associateWallet = () => {
        const totalAssociates = associateWalletList.length;
        let found = false;
        for (let i = 0; i < totalAssociates; i++) {
            if (associateWalletList[i].address.toLowerCase() === connectWallet.toLowerCase()) {
                found = true;
            }
        }
        if (!found) {
            if (web3.utils.isAddress(connectWallet)) {
                helpers.associateWallet(connectWallet)
            }
        }
    };

    const getUserTokens = async () => {
        if (!walletAddress) return

        if (isSignAndAuth) {
            await getWalletTokens();
            return
        }

        setInputTokenLoader(true);
        await getWeb3WalletTokens();
        setInputTokenLoader(false);
    };

    const getSwapTransactionsData = () => {
        helpers.transactionTypes(TOKEN_TO_TOKEN)
            .then(resp => {
                if (resp.data.success) {
                    const transTypeData = resp.data.data[0];
                    setMinimumAmount(transTypeData.min_deposit_amount);
                    setMaximumAmount(transTypeData.max_deposit_amount);
                }
            })
            .catch((error) => {
                setOutputTokenLoader(false);
                snackBarMessage({message: error.toString()});
            });
    };

    const handleBalanceError = () => {
        setInputTokenLoader(false);
        setConnectWallet('');
        setWalletAddress('');
        setInputTokenList(balanceTokenList);
        snackBarMessage({message: "Please select the Ethereum chain from MetaMask extension"});
    };

    const getWeb3WalletTokens = async () => {
        const tokenData = balanceTokenList;
        const totalTokens = tokenData.length;
        const tokenArr = [];
        let defautToken = "";
        for (let i = 0; i < totalTokens; i++) {
            const currentToken = tokenData[i];
            if (!currentToken.synthetic && currentToken.regionEu) {
                let balance;
                if (currentToken.token) {
                    try {
                        balance = await getTokenBalance(walletAddress, currentToken, (connected ? provider : null));
                    } catch(e) {
                        handleBalanceError();
                        break;
                    }
                } else if (currentToken.crypto) {
                    try {
                        balance = await getEthBalance(walletAddress, (connected ? provider : null));
                    } catch(e) {
                        handleBalanceError();
                        break;
                    }
                }
                if (balance > 0) {
                    // currentToken.fmt_balance = balance+"";
                    const newCurrentToken = {...currentToken, fmt_balance: balance+""};
                    tokenArr.push(newCurrentToken);
                    if (newCurrentToken.symbol === DEFAULT_CRYPT_TOKEN && (newCurrentToken.fmt_balance * 1) > 0) {
                        defautToken = newCurrentToken;
                    }
                }
            }
            setInputTokenList(tokenArr);
            if (defautToken) {
                setInputToken(defautToken);
            }
        }
    };

    const getWalletTokens = async () => {
        setInputTokenLoader(true);
        setOutputTokenLoader(true);

        helpers.getUserTokens(walletAddress)
            .then(resp => {
                setInputTokenLoader(false);
                setOutputTokenLoader(false);
                if (!resp.data.success) {
                    snackBarMessage({
                        message:
                            'Something went wrong while getting fiat currency data',
                    });
                }


                const tokenList = resp.data.data;
                tokenList.sort((a, b) => {
                    if (a.symbol < b.symbol) return -1;
                    if (a.symbol > b.symbol) return 1;
                    return 0;
                });
                const tokenArr = [];
                for (let i = 0; i < tokenList.length; i++) {
                    const currentToken = tokenList[i];
                    if (
                        !currentToken.tradeable ||
                        tokenSymbols.indexOf(currentToken.symbol) === -1
                    ) {
                        continue;
                    }

                    // Check if the token has a pair
                    if (!tokenPairs[currentToken.id]) {
                        continue;
                    }

                    const balance =
                        currentToken.fmt_balance.replaceAll(',', '');

                    const tokenToPush = {
                        id: currentToken.id,
                        name: currentToken.name,
                        symbol: currentToken.symbol,
                        address: currentToken.address,
                        fmt_balance: balance,
                        image: currentToken.icon_uri,
                        network: currentToken.network,
                        exchange: currentToken.exchange,
                        contractAddress: currentToken.address,
                        decimal: currentToken.decimals
                    };
                    tokenArr.push(tokenToPush);
                    if (currentToken.symbol === DEFAULT_CRYPT_TOKEN) {
                        setInputToken(tokenToPush);
                    }
                }
                setInputTokenList(tokenArr);
            })
            .catch((error) => {
                setInputTokenLoader(false);
                snackBarMessage({message: error.toString()});
            });
    };

    const getUserWallets = async () => {
        return helpers.getUserWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const getAssociatedWallets = async () => {
        return helpers.getAssociatedWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const resetEstimations = () => {
        setNetworkFee(0);
        setEverestFee(0);
        setFee(0);
        setInputFiatAmount(0);
        setOutputFiatAmount(0);
        setEstimateTokenValue('');
        setConversionRate('');
    };

    const resetSwap = () => {
        resetEstimations();
        setQuoteId('');
        setAmount('');
    };

    const getEstimations = () => {
        const data = {
            type: TOKEN_TO_TOKEN,
            amount: amountCal,
            exchange: inputToken.exchange,
            tokenIn: inputToken.symbol,
            networkIn: getCentralLedgetNetwork(inputToken.network),
            tokenOut: outputToken.symbol,
            networkOut: getCentralLedgetNetwork(outputToken.network),
            exchangeOut: outputToken.exchange,
        };

        if (typeof assetSource !== 'undefined') {
            assetSource.cancel('cancel');
        }
        assetSource = cancelToken.source();
        setEstimateLoader(true);
        helpers
            .feeTradeEstimator(data, assetSource)
            .then((response) => {
                setEstimateLoader(false);
                if (!response.data.success) {
                    snackBarMessage({
                        message:
                            'Something went wrong while getting estimates.',
                    });
                    return
                }

                const respData = response.data.data;
                setAmountErr(false);
                setErrorText("");
                setQuoteId(respData.quote_id);
                setEstimateTokenValue(respData.output_token.amount);
                setInputFiatAmount(respData.input_token.fiat_amount);
                setOutputFiatAmount(respData.output_token.fiat_amount);
                setConversionRate(respData.conversion_price);
                setFee(respData.total_fee * 1);
                const fee_breakdown = respData.fee_breakdown;
                for (let i = 0; i < fee_breakdown.length; i++) {
                    const currentFee = fee_breakdown[i];
                    switch (currentFee.name) {
                        case "ev_fee":
                            setEverestFee(currentFee.value * 1);
                            break;
                        case "network_fee":
                            setNetworkFee(currentFee.value * 1);
                            break;
                    }
                }
                if (minimumAmount * 1 > respData.input_token.fiat_amount * 1) {
                    setAmountErr(true);
                    setErrorText(`Minimum value of token should be greater than $${minimumAmount}`);
                }

                if (maximumAmount * 1 < respData.input_token.fiat_amount * 1) {
                    setErrorText(`Maximum value of token should be less than $${maximumAmount}`);
                    setAmountErr(true);
                }

            })
            .catch((error) => {
                if(error.message === "cancel") {
                    return;
                }
                setEstimateLoader(false);
                handleOrderError({error, snackBarMessage});
            });
    };

    const handleAmountChange = (event) => {
        const val = event.target.value;
        if (!val) {
            setAmount('');
            return;
        }

        const amountRegex = /^([0-9.,]+)$/;
        if (amountRegex.test(val)) {
            setAmount(formatNumberWithCommas(formatNumberWithoutRounding(val, 8)));
        }
    };

    const outputTokenSelect = (val) => {
        setOutputToken(val);
    };

    const inputTokenSelect = (val) => {
        setInputToken(val);
    };

    const sendTransaction = (toAddress, oId) => {
        setProviderLoader(true);
        sendProviderTransaction({
            fromAddress: connectWallet,
            toAddress: toAddress,
            token: inputToken,
            amount: amount.replaceAll(",", ""),
            provider: walletProvider,
            metaMaskProvider: provider,
            handleError: (e) => {
                console.log(e);
                setProviderLoader(false);
                setLoading(false);
                setEstimateLoader(true);
                getEstimations();
                snackBarMessage({message: e.message});
            },
            handleSuccess: (hash) => {
                setLoading(false);
                confirmTxHash(hash, oId)
            }
        });
    };

    const confirmTxHash = (txHash, oId) => {
        if(!txHash) {
            snackBarMessage({message: "Please provide transaction hash of the send that you perform."});
            return false;
        }

        if(!validateTxHash(txHash)) {
            snackBarMessage({message: "Please provide a valid transaction hash."});
            return false;
        }

        if(!loading) {
            setLoading(true);
            const data = {
                order_id: oId,
                tx_hash: txHash

            };
            helpers.confirmDeposit(data)
                .then(resp => {
                    setProviderLoader(false);
                    setLoading(false);
                    if(isLoggedIn()) {
                        setConfirmDialog({
                            title: "Swap Success",
                            message: "Your transaction was successful for more details on the transaction please track your order.",
                            cancelButtonText: "Swap again",
                            onCancel: () => {dispatch(reset()); resetEstimations();},
                            confirmButtonText: "Track Order",
                            onConfirm: () => {dispatch(reset()); setAmount(''); resetEstimations(); navigate('/order/'+oId);},
                            height: '490px',
                            icon: DialogSuccess
                        });
                    } else {
                        setConfirmDialog({
                            title: "Swap Success",
                            message: "Your transaction was successful.",
                            confirmButtonText: "Swap again",
                            onConfirm: () => {dispatch(reset()); setAmount(''); resetEstimations();},
                            cancelButtonShow: false,
                            height: '490px',
                            icon: DialogSuccess
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    snackBarMessage({message: error.toString()});
                    setConfirmDialog({
                        title: "Swap Error",
                        message: "There has been an error with processing your transaction.",
                        confirmButtonShow: false,
                        cancelButtonText: "Return to Swap Main Screen",
                        onCancel: () => { resetEstimations();},
                        height: '490px'
                    });
                });
        }
    };

    const getDepositAddress = (oId) => {
        i++;
        helpers.getDepositAddress(oId).then(resp => {
            if (resp.data.success) {
                const address = resp.data.data.address;
                if (address) {
                    if(walletProvider === META_MASK) {
                        sendTransaction(address, oId);
                    } else {
                        setLoading(false);
                        navigate('/swap/send', {
                            state: {
                                address,
                                orderId: oId
                            }
                        });
                    }
                } else {
                    if (i < 10) {
                        timeoutRef.current = setTimeout(() => {
                            getDepositAddress(oId)
                        }, 10000);
                    } else {
                        setLoading(false);
                        getEstimations();
                        snackBarMessage({message: "We are unable to get the deposit address. Please try again later"});
                    }
                }
            } else {
                snackBarMessage({message: "Something went wrong while getting address."});
            }
        }).catch((error) => {
            snackBarMessage({message: "Can't get Deposit Address:" + error.toString()});
        });
    };

    const tradeToken = () => {
        if (!isSignIn && enableAuth) {
            snackBarMessage({message: "Please SignIn/SignUp to proceed further with swap"});
            return false;
        }

        if (!inputToken.id) {
            snackBarMessage({message: "Please select token you want to swap"});
            return false;
        }

        if (!outputToken.id) {
            snackBarMessage({message: "Please select token you want to receive"});
            return false;
        }

        if (!amount) {
            snackBarMessage({message: "Please enter amount"});
            return false;
        }

        if (!walletAddress) {
            snackBarMessage({message: "Please select/enter wallet address"});
            return false;
        }

        if (!regexAmountCheck.test(amount)) {
            snackBarMessage({message: "Please provide a valid amount"});
            return false;
        }

        if (!estimateTokenValue) {
            snackBarMessage({message: "Please wait for the estimate to be calculated and then press next"});
            return false;
        }

        if (amount * 1 > inputToken.fmt_balance * 1) {
            snackBarMessage({message: "You don't have enough balance to make this swap"});
            return false;
        }

        if (minimumAmount * 1 > inputFiatAmount * 1) {
            snackBarMessage({message: "The input amount of this transaction is below our minimum, please try a higher transaction amount"});
            return false;
        }

        if (maximumAmount * 1 < inputFiatAmount * 1) {
            snackBarMessage({message: "The input amount of this transaction is above our maximum, please try a lower transaction amount"});
            return false;
        }

        if (loading) {
            return false;
        }
        setLoading(true);
        helpers
            .createOrder({quote_id: quoteId, wallet_address: walletAddress, referral_code: referralCode}, appKey)
            .then((resp) => {
                if (resp.data.success) {
                    dispatch(setData({amount, token: inputToken, recieveToken: outputToken, address: walletAddress}));
                    const respData = resp.data.data;
                    const orderId = respData.order_id;
                    if (manualSend) {
                        i = 0;
                        getDepositAddress(orderId);
                    } else {
                        setLoading(false);
                        setConfirmDialog({
                            title: "Swap Success",
                            message: "Your transaction was successful for more details on the transaction, please view your transaction history.",
                            cancelButtonText: "Swap again",
                            onCancel: () => {
                                dispatch(reset());
                                navigate('/swap');
                            },
                            confirmButtonText: "Track Order",
                            onConfirm: () => {
                                dispatch(reset());
                                navigate('/order/' + orderId);
                            },
                            height: '490px',
                            icon: DialogSuccess
                        });
                    }
                } else {
                    snackBarMessage({message: 'Something went wrong while creating order.'});
                }
            })
            .catch((error) => {
                setLoading(false);
                handleOrderError({
                    error, quoteConfirmFunc: () => {
                        setEstimateLoader(true);
                        getEstimations();
                    }, confirmFunc: () => {
                        dispatch(reset());
                        resetSwap();
                    }, snackBarMessage, navigate
                });
            });
    };

    const setMaxAmount = () => {
        setAmount(formatNumberWithCommas(formatNumberWithoutRounding(inputToken.fmt_balance, 8)));
        if(walletProvider !== META_MASK) {
            navigator.clipboard.writeText(inputToken.fmt_balance);
            snackBarMessage({message: "Max amount has been set & copied. Please send the exact amount of tokens.", type: "info", duration: 6000})
        }
    };

    return (
        <>
            <Box className="login-container">
                <BuySellLink tradeActive={true}/>
                <DesktopCompatible />
                <Box className="full-width">
                    <Grid mt={{xs:4, md: 8}} container>
                        <Grid item={true} xs={2.5}>
                            <Typography color="primary" className="input-label-feature" variant="caption" component="p">You
                                Swap</Typography>
                        </Grid>
                        <Grid item={true} xs={9.5}>
                            {walletAddress && inputToken.id &&
                                <Typography className="trade-balance-caption" sx={{color: theme.customGray.color}} variant="caption" component="p">You
                                    Have: {inputToken.fmt_balance} {inputToken.symbol} <span className='max-input-btn'
                                                                                             onClick={setMaxAmount}>MAX</span></Typography>}
                        </Grid>
                    </Grid>
                    <Grid sx={{background: "white", borderRadius: theme.tradeInput.borderRadius}} mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl variant="filled" className='pay-amount-field crypto-field'>
                                <FilledInput
                                    value={amount}
                                    onChange={handleAmountChange}
                                    type="text"
                                    placeholder={"$55 minimum value"}
                                    // placeholder={`${minimumAmount > 0 ? "$" + minimumAmount + " minimum value" : ""}`}
                                    error={amountErr}
                                    required
                                    fullWidth
                                />
                                <FormHelperText
                                    className="swap-fiat-values">{inputFiatAmount > 0 ? "$" + (inputFiatAmount * 1).toFixed(2) : <>&nbsp;</>}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            {inputTokenLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                                <FormControl variant="filled" className="text-left crypto-picker" fullWidth>
                                    {inputToken?.network && <InputLabel
                                        className={`select-network-label ${getNetworkBackGround(inputToken?.network)}`}>{getCentralLedgetNetwork(inputToken?.network)}</InputLabel>}
                                    <Select
                                        sx={{
                                            fontWeight: "bold",
                                            '.MuiSvgIcon-root ': {
                                                fill: "#16359D !important",
                                            },
                                            padding: inputToken.id > 0 ? "0px" : "4.95px"
                                        }}
                                        className="buy-select-dd"
                                        value={inputToken.id}
                                        variant="filled"
                                        displayEmpty
                                        onChange={(e) => inputTokenSelect(e.target.value)}
                                        onOpen={(e) => {
                                            setOpenInputTokenModal(true);
                                            e.preventDefault();
                                        }}
                                        defaultOpen={false}
                                        open={false}
                                        error={amountErr}
                                    >
                                        <MenuItem value="">Please select</MenuItem>
                                        {inputTokenList.map(fiat =>
                                            <MenuItem value={fiat.id} key={fiat.id}>
                                                <ListItemIcon className={"dropdown-list-item"}>
                                                    <img src={fiat.image} alt="US Flag" width="40" height="40"/>
                                                </ListItemIcon>
                                                <span>{fiat.symbol}</span>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        {errorText &&
                            <Typography variant="caption" sx={{color: 'red'}}><strong>{errorText}</strong></Typography>}
                    </Grid>

                    <Grid mt={4} container>
                        <Grid item={true} xs={4}>
                            <Typography color="primary" className="input-label-feature" variant="caption" component="p">You
                                Receive</Typography>
                        </Grid>
                        <Grid sx={{marginTop: "-20px"}} className="text-center" item={true} xs={4}>
                            <img src={ARROW_ICON} alt="arrow"/>
                        </Grid>
                    </Grid>

                    <Grid sx={{background: "white", borderRadius: theme.tradeInput.borderRadius}} mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl className='pay-amount-field crypto-field'>
                                <FilledInput
                                    value={estimateTokenValue && (estimateTokenValue * 1).toFixed(5)}
                                    type="text"
                                    disabled={true}
                                    inputProps={{sx: {cursor: "not-allowed"}}}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                          background: "#f0f0f0",
                                          borderRadius: theme.tradeInput.borderRadius
                                    }}}
                                    fullWidth
                                />
                                <FormHelperText
                                    className="swap-fiat-values">{outputFiatAmount > 0 ? "$" + (outputFiatAmount * 1).toFixed(2) : <>&nbsp;</>}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            {outputTokenLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                                <FormControl variant="filled" className="text-left crypto-picker" fullWidth>
                                    {outputToken?.network && <InputLabel
                                        className={`select-network-label ${getNetworkBackGround(outputToken?.network)}`}>{outputToken?.network}</InputLabel>}
                                    <Select
                                        {...(appKey === VIAARPE_APP_KEY ? {IconComponent:  () => null }: {})}
                                        sx={{
                                            fontWeight: "bold",
                                            '.MuiSvgIcon-root ': {
                                                fill: "#16359D !important",
                                            },
                                            padding: outputToken.id > 0 ? "0px" : "4.95px",
                                            '& .MuiSelect-select.Mui-disabled': {
                                                cursor: 'not-allowed',
                                                background: "#f0f0f0",
                                                WebkitTextFillColor: "#16359D",
                                                pointerEvents: 'all !important',
                                                borderRadius: theme.tradeInput.borderRadius
                                            }
                                        }}
                                        className={"buy-select-dd"}
                                        variant="filled"
                                        value={outputToken.id}
                                        displayEmpty
                                        onChange={(e) => outputTokenSelect(e.target.value)}
                                        onOpen={(e) => {
                                            setOpenOutputModal(true);
                                            e.preventDefault();
                                        }}
                                        defaultOpen={false}
                                        open={false}
                                        disabled={appKey === VIAARPE_APP_KEY}
                                    >
                                        <MenuItem value="">Please select</MenuItem>
                                        {outputTokenList.map(token =>
                                            <MenuItem key={token.id} value={token.id}>
                                                <ListItemIcon className={"dropdown-list-item"}>
                                                    <img src={token.image} onError={({currentTarget}) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = GenericTokenImage;
                                                    }} alt="tokenImage" width="40" height="40"/>
                                                </ListItemIcon>
                                                <span>{token.symbol.toUpperCase()}</span>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>

                    <Box mt={2.5} className='fee-area' color={theme.customGray.color}>
                        <Grid container>
                            <Grid className='text-left' item={true} xs={12}>
                                <IconButton sx={{padding: "0"}} onClick={() => setShowFeeCalculation(prev => !prev)}
                                            disableFocusRipple={true} disableRipple={true}><Typography
                                    variant="caption" color={theme.fee.color}><strong>Fee Calculation</strong></Typography><PlayArrowIcon sx={{
                                    transform: `rotate(${showFeeCalculation ? "90" : "0"}deg)`,
                                    fontSize: '15px',
                                    color: theme.fee.color
                                }}/></IconButton>
                            </Grid>
                            {showFeeCalculation &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant="caption">Everest Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant="caption">{currencySymbol}{estimateLoader ?
                                            <CircularProgress sx={{ml: 1}} size={15}
                                                              color={"custom"}/> : everestFee.toFixed(2)}</Typography>
                                    </Grid>

                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant="caption">Network Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant="caption">{currencySymbol}{estimateLoader ?
                                            <CircularProgress sx={{ml: 1}} size={15}
                                                              color={"custom"}/> : networkFee.toFixed(2)}</Typography>
                                    </Grid>
                                </>
                            }
                            <Grid className="text-left" item={true} xs={6}>
                                <Typography variant="caption">Total Fees</Typography>
                            </Grid>
                            <Grid className="text-right" item={true} xs={6}>
                                <Typography variant="caption">{currencySymbol}{estimateLoader ?
                                    <CircularProgress sx={{ml: 1}} size={15}
                                                      color={"custom"}/> : fee.toFixed(2)}</Typography>
                            </Grid>
                            {outputToken.id && inputToken.id &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant="caption">1 {inputToken?.symbol} <BootstrapTooltip
                                            title={ESTIMATE_TOOLTIP_TEXT} placement="right"><IconButton
                                            className="info-fee-icon" sx={{color: theme.customGray.color}}><InfoIcon
                                            sx={{fontSize: '14px'}}/></IconButton></BootstrapTooltip></Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant="caption">{estimateLoader ?
                                            <CircularProgress sx={{ml: 1}} size={15}
                                                              color={"custom"}/> : (exchangeRate * 1).toFixed(6) + " " + outputToken?.symbol}</Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>

                    <ConnectWallet snackBarMessage={snackBarMessage}
                                   walletAddress={connectWallet} from={"swap"}
                                   addressHandler={handleConnectWalletChange}/>

                    {isSignAndAuth ?
                        <Box sx={{alignItems: 'left', width: '100%'}}>
                            {loader ? <Box mt={2} sx={{textAlign: 'center'}}><CircularProgress/></Box> :
                                <>
                                    <Typography className='wallet-caption-text' mt={1} mb={1} variant="caption"
                                                component="p">Or</Typography>
                                    <FormControl className="wallet-select-form-control" variant="filled" fullWidth>
                                        {!selectWallet && <InputLabel>Select wallet address</InputLabel>}
                                        <Select
                                            className="wallet-select-dd"
                                            value={selectWallet}
                                            onChange={handleWalletChange}
                                        >
                                            {walletList.map(wallet =>
                                                <MenuItem key={wallet.address}
                                                          value={wallet.address}>{wallet.name + '(' + wallet.address + ')'}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </>
                            }
                        </Box> :
                        <>
                        {enableAuth &&
                                <>
                            <Button
                                    onClick={() => navigate('/signin', {state: {from: 'swap'}})} sx={{mt: 2}}
                                    variant="themeContained" fullWidth>
                                <strong>Sign In</strong>
                            </Button>
                            <Typography className={"connect-wallet-signup"} variant="caption"
                                        onClick={() => navigate('/signup', {state: {from: 'swap'}})}><strong>Sign Up
                                With Everest</strong></Typography>
                                </>
                            }
                        </>
                    }

                    <Box mt={4}>
                        {(isSignAndAuth || connectWallet) &&
                            <Button
                                fullWidth
                                variant="themeContained"
                                onClick={tradeToken}
                                disabled={amountErr || !estimateTokenValue || estimateLoader}
                            >
                                <strong>{loading ? <><CircularProgress size={15}
                                                                       color="inherit"/> Processing...</> : "Swap"}</strong>
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>

            <Dialog
                open={providerLoader}
                fullWidth={true}
                sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                    maxWidth: '675px',
                    },
                },
                }}
            >
                <DialogContent sx={{ mt: 3 }} className="swap-progress-modal">
                    <Typography variant="h4" className="text-center">
                        <strong>Swapping in Progress</strong>
                    </Typography>
                    <Typography
                        sx={{ mt: 5 }}
                        variant="h6"
                        className="text-center fw500"
                    >
                        Your transaction is processing. Please do not refresh or leave this page…
                    </Typography>
                    
                    <Box mt={2} sx={{ color: '#00B171', textAlign: 'center' }}>
                        <CircularProgress size={50} color="custom" />
                    </Box>
                </DialogContent>
            </Dialog>
            

            <TokenModal
                open={openInputTokenModal}
                setModal={setOpenInputTokenModal}
                loader={inputTokenLoader}
                tokenList={inputTokenList}
                tokenSelect={inputTokenSelect}
            />

            <TokenModal
                open={openOutputModal}
                setModal={setOpenOutputModal}
                loader={outputTokenLoader}
                tokenList={outputTokenList}
                tokenSelect={outputTokenSelect}
            />
        </>
    );
};

export default Trade;
