
import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

const FullPageLoader = () => {
  return (
    <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress size={50} color="secondary" />
    </Backdrop>
  );
};

export default FullPageLoader;