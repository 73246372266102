/* Types */

import { PARTNER_VIAARPE_APP_KEY, PARTNER_VIAARPE_BUY_APP_KEY } from "./variables";

export const FIAT_TO_TOKEN = 'fiat_to_token';
export const FIAT_TO_EVER_ID = 'fiat_to_everid';
export const TOKEN_TO_FIAT = 'token_to_fiat';
export const TOKEN_TO_TOKEN = 'token_to_token';
export const FIAT_WITHDRAW = 'withdraw';

export const DOLLAR_SIGN = "$";
export const EURO_SIGN = "€";

export const DEFAULT_CRYPT_TOKEN = "ETH";
export const ID_TOKEN_SYMBOL = "ID";
export const BTC_TOKEN_SYMBOL = "BTC.c";


/* Synthetics */

export const SYNTHETIC_USD = 'USD.c';
export const SYNTHETIC_EUR = 'EUR.c';

export const USD_SYMBOL = "USD";
export const EUR_SYMBOL = "EUR";

/* Networks */

export const BINANCE_NETWORK = 'Binance';
export const POLYGON_NETWORK = 'Polygon';
export const EVER_CHAIN_NETWORK = 'EverChain';
export const ID_CHAIN_NETWORK = 'IDChain';
export const ETHEREUM_NETWORK = 'Ethereum';
export const AVALANCHE_NETWORK = 'Avalanche';

/* Wallet Provider */
export const META_MASK = "MetaMask";
export const COIN_98 = "Coin98";
export const WALLET_CONNECT = "WalletConnect";
export const COIN_BASE = "CoinBase";
export const FORTMATIC = "Fortmatic";
export const PORTIS = "Portis";


/* Payment Providers */
export const PAYMENT_PROVIDER_RAILSBANK = "rails_bank";
export const PAYMENT_PROVIDER_OPENPAYD = "open_payd";
export const PAYMENT_PROVIDER_PLAID = "plaid"
export const PAYMENT_PROVIDER_CHECKOUT = "checkout";
export const PAYMENT_PROVIDER_TRUST_PAYMENTS = "trust_payment";

/* Payment methods */
export const PAYMENT_CARD = 'credit_debit_card';
export const PAYMENT_BANK = 'sepa_bank_transfer';
export const PAYMENT_APPLE = 'apple_pay';
export const PAYMENT_GOOGLE = 'google_pay';

/* Networks */
export const NETWORK_EVER_CHAIN = 'EverChain';
export const NETWORK_ETHEREUM = 'Ethereum';

/* Transaction Status */
export const TS_CREATED_LABEL = 'Created';
export const TS_PENDING_LABEL = 'In Process';
export const TS_COMPLETE_LABEL = 'Completed';
export const TS_FAIL_LABEL = 'Failed';
export const TS_WAIT_APPROVAL_LABEL = 'Awaiting Approved';
export const TS_NOT_APPROVAL_LABEL = 'Rejected';

/* Region & Country */
export const COUNTRY_ISO3_US = 'USA';
export const REGION_US = 'US';
export const REGION_EU = 'EU';

/* SDK Service Modes */
export const SERVICE_BUY_SELL = 'buySell';
export const SERVICE_IDENTITY = 'identity';

/* Documents */
export const DOCUMENT_TYPE_PASSPORT = 'PASSPORT';
export const DOCUMENT_TYPE_ID_CARD = 'ID_CARD';
export const DOCUMENT_TYPE_DRIVING_LICENSE = 'DRIVING_LICENSE';
export const DOCUMENT_TYPE_VISA = 'VISA';
export const DOCUMENT_SCAN_FRONT = 'Front';
export const DOCUMENT_SCAN_BACK = 'Back';

export const ESTIMATE_TOOLTIP_TEXT = 'The rate shown here is an estimate and the rate you receive will be determined at the time when your payment is settled. The time taken for your payment to settle can vary depending on currency and payment method.';
export const EVEREST_FEE_TOOLTIP_TEXT = 'This is the Everest transaction fee, there are no other fees we charge.';
export const NETWORK_FEE_TOOLTIP_TEXT = 'This is the fee that Everest’s partners charge for service and delivery.';

export const EVER_WALLET_NAME = "EverWallet";

/* Campaigns */
export const CAMPAIGN_TWITTER = 'twitter';
export const CAMPAIGN_NON_MLM = 'nonMlm';
export const CAMPAIGN_EXCHANGE = 'exchange';

export const STATIC_ASSETS_BASE_URL = 'https://static-assets.everest.org';
export const WIDGET_URL = STATIC_ASSETS_BASE_URL+'/web/widget';
export const VIAARPE_APP_KEY = PARTNER_VIAARPE_APP_KEY;
export const VIAARPE_BUY_APP_KEY = PARTNER_VIAARPE_BUY_APP_KEY;
