import React, {useState, useEffect, useCallback} from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import helpers from '../../adapters/helpers';
import {Box, Typography, Button, Grid, CircularProgress} from '@mui/material';
import {selectBuyData, reset, setData} from './reduxSlice';
import {getCurrencySymbol} from "../../util/generalUtil";
import Sepa from '../../images/sepa.png';
import Visa from '../../images/visa.png';
import {EVER_CHAIN_NETWORK, FIAT_TO_EVER_ID, FIAT_TO_TOKEN, ID_TOKEN_SYMBOL, PAYMENT_BANK, PAYMENT_CARD} from "../../js/constants";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import DialogSuccess from "../../images/dialog-success.png";
import {selectAppData, setBackFunc} from "../../store/reduxSlice";
import BackBtn from '../Shared/backBtn';
import { handleOrderError } from '../Shared/errorHandler';

const ConfirmTransaction = ({snackBarMessage}) => {
    const buyData = useSelector(selectBuyData);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    const referralCode = appData.referralCode;
    const [linkToken, setLinkToken] = useState('');
    const [renderPlaid, setRenderPlaid] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [orderId, setOrderId] = useState(false);

    const totalAmount = (buyData.amount.replaceAll(",", ".") * 1);
    const currencySymbol = getCurrencySymbol(buyData.currency);

    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/')));
        getEstimations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEstimations = () => {
        const amountCal = buyData.amount.replaceAll(",", ".") * 1;
        const data = {
            type: (buyData.token === ID_TOKEN_SYMBOL && buyData.network === EVER_CHAIN_NETWORK) ? FIAT_TO_EVER_ID : FIAT_TO_TOKEN,
            paymentMethod: buyData.paymentMethod,
            amount: amountCal,
            token: buyData.token,
            currency: buyData.currency,
            network: buyData.network,
            exchange: buyData.exchange,
            paymentProvider: buyData.provider
        };
        helpers.feeEstimator(data)
            .then((response) => {
                setBtnLoading(false);
                if (response.data.success) {
                    let everestFee = 0, networkFee = 0;
                    const respData = response.data.data;
                    const quoteId = respData.quote_id;
                    const estimatedTokenAmount = respData.crypto_out_amount;
                    const conversionRate = respData.conversion_price;
                    const fee = respData.total_fee * 1;
                    const fee_breakdown = respData.fee_breakdown;
                    for(let i = 0; i < fee_breakdown.length; i++) {
                        const currentFee = fee_breakdown[i];
                        if(currentFee.name === "ev_fee") {
                            everestFee = currentFee.value * 1;
                        } else if(currentFee.name === "network_fee") {
                            networkFee = currentFee.value * 1;
                        }
                    }
                    dispatch(setData({totalFee: fee, quoteId, networkFee, everestFee, conversionRate, estimatedTokenAmount}));
                }
            })
            .catch((error) => {
                setBtnLoading(false);
                handleOrderError({error, snackBarMessage});
            });
    };

    const onPlaidSuccess = useCallback(() => { //public_token, metadata
        setConfirmDialog({
            title: "Buy Success",
            message: "Your transaction was successful. It can take a few minutes to receive your token.",
            cancelButtonText: "Buy again",
            onCancel: () => {dispatch(reset()); navigate('/');},
            confirmButtonText: "Track Order",
            onConfirm: () => {dispatch(reset()); navigate('/order/'+orderId);},
            height: '490px',
            icon: DialogSuccess
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    const config = {
        onSuccess: onPlaidSuccess,
        // onExit: (err, metadata) => {
        //     setRenderPlaid(prev => !prev);
        // },
        token: linkToken,
    };

    const { open, ready } = usePlaidLink(config);

    useEffect(() => {
        if(ready) {
            open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, renderPlaid]);

    const processTransaction = () => {
        setBtnLoading(true);
        helpers.createOrder({quote_id: buyData.quoteId, wallet_address: buyData.address, payment_provider: buyData.provider, referral_code: referralCode}, appKey)
            .then(resp => {
                setBtnLoading(false);
                if(resp.data.success) {
                    const respData = resp.data.data;
                    const token = respData.provider_token;
                    setOrderId(respData.order_id);
                    if(buyData.paymentMethod === PAYMENT_CARD) {
                        navigate('/card/payment', {
                            state: {
                                jwtToken: token,
                                orderId: respData.order_id,
                                amount: buyData.amount.replaceAll(",", ".") * 1,
                                currency: buyData.currency
                            }
                        });
                    } else {
                        helpers
                            .createPlaidLinkToken({ payment_id: token })
                            .then((response) => {
                                setBtnLoading(false);
                                if (response.data.success && response.data.data) {
                                    if (response.data.data) {
                                        setLinkToken(response.data.data);
                                        setRenderPlaid(prev => !prev);
                                    }
                                }
                            })
                            .catch((error) => {
                                setBtnLoading(false);
                                console.log(error);
                                snackBarMessage({message: "We ran into an issue trying to connect your bank"});
                            });
                    }
                } else {
                    snackBarMessage({message: "Something went wrong while creating order."});
                }
            }).catch(error => {
            setBtnLoading(false);
            handleOrderError({error, snackBarMessage, navigate, quoteConfirmFunc: () => {setBtnLoading(true); getEstimations();}, confirmFunc: () => {dispatch(reset()); navigate('/')}});
        });
    };

    return (
        <>
            <BackBtn onClickHandler={() => navigate('/')} backBtnText={"Confirm Your Order"} />
            <Typography mt={4} variant="body2"><b>Using payment method</b></Typography>
            <Box mt={2} className="confirm-payment">
                <img src={buyData.paymentMethod === PAYMENT_BANK ? Sepa : Visa} width={100} alt="payment" />
                <Typography variant="caption" component="p"><strong>{buyData.paymentMethod === PAYMENT_BANK ? "Bank Account" : "Debit Card"}</strong></Typography>
            </Box>
            <Typography mt={4} variant="body2"><b>To Wallet Address</b></Typography>
            <Box mt={2} className="confirm-address">
                <Typography variant="caption" component="p"><strong>{buyData.address}</strong></Typography>
            </Box>
            <Typography mt={3} variant="caption" component="p"><b>Order Estimate Details</b></Typography>

            <Box sx={{color: '#A6A6A6'}}>
                <Grid sx={{padding: '10px'}} container>
                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">{buyData.token.toUpperCase()} @ {(buyData.conversionRate * 1).toFixed(2)} {buyData.currency}</Typography>
                    </Grid>
                    <Grid className='flex-right' item={true} xs={6}>
                        <span className="confirm-transaction-network" style={{backgroundColor: buyData.network === "Ethereum" ? '#A1A9DC' : '#5D8CCC'}}>{buyData.network}</span>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">{(buyData.estimatedTokenAmount * 1).toFixed(5)} {buyData.token.toUpperCase()}</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(totalAmount - buyData.totalFee).toFixed(2)} {buyData.currency}</Typography>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">Total Fees</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(buyData.totalFee).toFixed(2)} {buyData.currency}</Typography>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption"><strong>Order Total</strong></Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption"><strong>{currencySymbol}{(totalAmount).toFixed(2)} {buyData.currency}</strong></Typography>
                    </Grid>
                </Grid>
            </Box>

            <Button
                className="sdk-btn2 mt32"
                variant="contained"
                onClick={processTransaction}
                fullWidth
            >
                <strong>{btnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Payment Details"}</strong>
            </Button>
        </>
    );
};

export default ConfirmTransaction;
